<template>
    <div v-if="inspections[status].data && inspections[status].data.length">
        <Pagination :pagination="inspections[status].pagination" entity="inspections" :status="status"/>
        <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <InspectionCard
                v-for="(inspection, index) in inspections[status].data"
                :key="index"
                :inspection="inspection"
                :shortened="shortened"
                @schedule-inspection="$emit('schedule-inspection', $event)"
            />
        </ul>
        <Pagination :pagination="inspections[status].pagination" entity="inspections" :status="status"/>
    </div>
    <p v-else class="mt-6">
        {{ emptyMessage }}
    </p>
</template>

<script>
import Pagination from '@/components/layout/Pagination.vue'
import InspectionCard from '@/components/inspections/InspectionCard.vue'
import { mapState } from 'vuex'

export default {
    name: 'InspectionsPagination',
    components: {
        Pagination,
        InspectionCard
    },
    emits: ['schedule-inspection'],
    props: {
        shortened: Boolean,
        stickyTop: String,
        emptyMessage: String,
        installationId: Number,
        status: String
    },
    computed: {
        ...mapState(['inspections'])
    },
    async created() {
        const searchParams = new URLSearchParams()

        let status = this.status
        let key = this.status
        if (this.status == 'scheduled') {
            searchParams.append('exists[appointmentDate]', 1)
            status = 'pending'
        }
        if (this.installationId) {
           searchParams.append('customDevice.installation.id', this.installationId)
        }

        const query = searchParams.toString() ? `&${searchParams.toString()}` : ''

        this.$store.dispatch('setLoading', true)
        await this.$store.dispatch('getCollection', { entity: 'inspections', key, status, page: 1, query, installationId: this.installationId })
        this.$store.dispatch('setLoading', false)
    },
}
</script>