<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{$t("home.inspection.scheduleInspection")}}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <p class="mt-6">
                      {{$t("home.inspection.completionDate")}}: <span class="font-bold">{{ new Date(inspection.scheduledDate).toLocaleDateString('pl-PL') }}</span>
                    </p>
                    <p v-if="inspection.appointmentDate" class="mt-2">
                      {{$t("home.inspection.currentlyScheduled")}}: <br /><span class="font-bold">{{ new Date(inspection.appointmentDate).toLocaleString('pl-PL') }}</span>
                    </p>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <div class="space-y-2">
                      <InputWithError
                        v-model="date"
                        id="date"
                        name="date"
                        type="date"
                        autocomplete="date"
                        :label="$t('date')"
                      />
                      <InputWithError
                        v-model="time"
                        id="time"
                        name="time"
                        type="time"
                        autocomplete=""
                        :label="$t('time')"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                    {{$t("cancel")}}
                  </button>
                  <button @click="scheduleInspection" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{$t("save")}}
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import InputWithError from '@/components/inputs/InputWithError.vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from 'vuex'

export default {
    components: {
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      XMarkIcon,
      InputWithError
    },
    props: {
      inspection: Object
    },
    data () {
      return {
        open: false,
        date: null,
        time: null
      }
    },
    computed: {
      ...mapState(['user'])
    },
    methods: {
      show () {
        this.date = this.inspection.appointmentDate ? new Date(this.inspection.appointmentDate).toLocaleString('sv').slice(0,10) : new Date().toLocaleString('sv').slice(0,10),
        this.time = this.inspection.appointmentDate ? new Date(this.inspection.appointmentDate).toLocaleTimeString('sv').slice(0,5) : new Date().toLocaleTimeString('sv').slice(0,5)
        this.open = true
      },
      scheduleInspection() {
        console.log(this.time)
        const localtime = this.date + ' ' + this.time // - new Date().getTimezoneOffset() * 60000
        const d = localtime.split(/[^0-9]/)
        const date = new Date(d[0],d[1]-1,d[2],d[3],d[4])

        const scheduledDate = new Date(this.inspection.scheduledDate)

        const data = {
          id: this.inspection.id,
          appointmentDate: date.toLocaleString('sv'),
          soonestDate: date.getTime() < scheduledDate.getTime() ? date.toLocaleString('sv') : scheduledDate.toLocaleString('sv')
        }

        this.open = false
        this.$nextTick(() => { this.$store.dispatch('setLoading', true) })
        api.patch(`/inspections/${this.inspection.id}`, data, patchHeaders).then(result => {
          console.log(result)
          this.$store.dispatch('getNotifications')
          this.$store.dispatch('getCollection', { entity: 'inspections', status: 'pending' })
          this.$store.dispatch('getCollection', { entity: 'inspections', key: 'scheduled', status: 'pending' })
          this.$store.dispatch('setLoading', false)
          this.$notify({
            title: "Sukces!",
            text: `Przegląd został umówiony na ${date.toLocaleString('pl-PL')}. Poinformujemy Cię gdy termin będzie blisko.`,
            type: 'success'
          })
        }).catch(err => {
          this.$store.dispatch('setLoading', false)
          this.$notify({
            title: "Podczas próby połączenia z bazą danych wystąpił błąd:",
            text: err.response.data.detail,
            type: 'success'
          })
        })
      }
    }
}
</script>
