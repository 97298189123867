<template>
  <div class="relative">
    <!-- menu tabs -->
    <div :style="{top: stickyTop}" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 border-b border-gray-200 bg-white z-10 sticky">
      <nav class="flex justify-between md:space-x-8 md:justify-start" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[(currentTab === tab.slug) ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']"
          @click="currentTab = tab.slug"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <InspectionsPagination 
        v-for="tab in tabs"
        :key="tab.slug"
        v-show="currentTab === tab.slug"
        :status="tab.status"
        :shortened="shortened"
        :installationId="installationId"
        :emptyMessage="tab.emptyMessage"
        @schedule-inspection="openScheduleModal"
      />
    </div>
    <ScheduleInspection
      ref="scheduleModal"
      :inspection="actionInspection"
    />
  </div>
</template>

<script>
import InspectionsPagination from '@/components/inspections/InspectionsPagination.vue'
import ScheduleInspection from '@/components/inspections/ScheduleInspection.vue'
import { PhoneIcon } from '@heroicons/vue/24/solid'
import { ClockIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    PhoneIcon,
    ClockIcon,
    InspectionsPagination,
    ScheduleInspection
  },
  props: {
    inspections: Array,
    shortened: Boolean,
    stickyTop: String,
    installationId: Number
  },
  data(){
    return{
      tabs: [
        { name: `${this.$t("home.inspection.upcoming")}`, slug: 'nadchodzace', status: 'pending', emptyMessage: this.$t("home.inspection.noPendingInspections") },
        { name: `${this.$t("home.inspection.scheduled")}`, slug: 'umowione',  status: 'scheduled', emptyMessage: this.$t("home.inspection.noScheduledInspections") },
        { name: `${this.$t("home.inspection.completed")}`, slug: 'zakonczone', status: 'done', emptyMessage: this.$t("home.inspection.noCompletedInspections") }
      ],
      currentTab: 'nadchodzace',
      actionInspection: null
    }
  },
  methods: {
    openScheduleModal(inspection) {
      this.actionInspection = inspection
      this.$nextTick(() => {
        this.$refs.scheduleModal.show()
      })
    }
  }
}
</script>
